<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
      scrollable
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
        >
          <v-img
            lazy-src="https://picsum.photos/id/11/10/6"
            max-height="50"
            max-width="50"
            :src="src"
          />
        </div>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2 py-1">
          {{ title }}
        </v-card-title>

        <v-card-text>
          <v-img
            lazy-src="https://picsum.photos/id/11/10/6"
            max-height="100%"
            max-width="100%"
            :src="src"
          />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default() {
        return "title";
      },
    },
    src: {
      type: String,
      default() {
        return "https://picsum.photos/id/11/10/6";
      },
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
